import React, { useState, useContext, useEffect } from 'react'
import { Upload } from '../../EntryFile/imagePath';
import { CircleSpinner } from "react-spinners-kit";
import { ApplicationAttributes } from '../../InitialPage/App';
import { FetchItemCategory, AddNewItems } from '../../api/Aws-api';
import { ConstructorEventGet, ConstructorEventPost, uploadImage } from '../../Event/ConstructorEvent';
import { successMessage, GetDateForStock, isNumber } from '../Functions/CommonFunctions';
import { useNavigate } from 'react-router-dom';


const AddNewItem = (props) => {
    const [Catogory, setCatogory] = useState()
    const [Catogory_options, setCatogory_options] = useState([])
    const [ItemImage, setItemImage] = useState(null);
    const [Refreshloading, setRefreshLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const { OutletCode } = useContext(ApplicationAttributes)
    const [ProductName, setProductName] = useState(null);
    const [Price, setPrice] = useState(null);
    const [Quantity, setQuantity] = useState(null);
    const [TaxPercentage, setTaxPercentage] = useState(null);
    const [UnitOfMeasure, setUnitOfMeasure] = useState(null);
    const [Description, setDescription] = useState(null);
    const navigate = useNavigate()
    const Date = GetDateForStock()


    useEffect(() => {
        async function fetchdata() {
            const responseData = await ConstructorEventGet(FetchItemCategory, { client_code: OutletCode });
            console.log(responseData)
            setCatogory_options(responseData ? responseData : [])
            setCatogory(responseData ? responseData[0]["category_code"] : "")
            setRefreshLoading(false)
        }
        setRefreshLoading(true)
        fetchdata()
    }, [])

    const handleProfileUpload = async (e) => {
        setLoading(true)
        const params = {
            "catogory": Catogory,
            "item_name": ProductName,
            "price": isNumber(Price) ? parseInt(Price) : 0,
            //"quantity": parseInt(Quantity),
            // "quantity": 1000,
            "stock": {
                "StockInboudDate": Date,
                "StockQuantity": isNumber(Quantity) && Quantity ? parseInt(Quantity) : 10
            },
            "tax_amount": 10,
            "tax_percentage": isNumber(TaxPercentage) && TaxPercentage ? parseInt(TaxPercentage) : 0,
            "unit_of_measure": UnitOfMeasure ? UnitOfMeasure : "",
            "Description": Description ? Description : ""
        }
        const responsedata = await ConstructorEventPost(AddNewItems, { params: params, imgtype: ItemImage ? ItemImage.type : "noImage", clientCode: OutletCode })
        if (responsedata !== "created") {
            const response = await uploadImage(responsedata, ItemImage)
            if (response["status"] == 200) {
                successMessage("Item SuccessFully Created")
                navigate("/dream-pos/item/ListItem")
            }
        }
        else { successMessage("Item SuccessFully Created"); navigate("/dream-pos/item/ListItem") }
        setLoading(false)
    }


    return (
        <>
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="page-title">
                            <h4>Add New Item</h4>
                        </div>
                    </div>
                    {/* /add */}
                    <div className="card">
                        <div className="card-body">
                            <CircleSpinner size={20} color="orange" loading={Refreshloading} />
                            <div className="row ">
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Item Name <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            type="text"
                                            value={ProductName}
                                            onChange={(e) => setProductName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12" >
                                    <div className="form-group">
                                        <label>Category <span style={{ color: "red" }}>*</span></label>
                                        <select value={Catogory} className="checkInput" onChange={(e) => setCatogory(e.target.value)}>
                                            {Catogory_options && Catogory_options.map((data, index) => (<option value={data["category_code"]}>{data["category_code"]}</option>))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Price <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            type="text"
                                            value={Price}
                                            onChange={(e) => setPrice(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Quantity</label>
                                        <input
                                            type="text"
                                            value={Quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Tax Percentage</label>
                                        <input
                                            type="text"
                                            value={TaxPercentage}
                                            onChange={(e) => setTaxPercentage(e.target.value)}
                                        />

                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Unit of measure</label>
                                        <input
                                            type="text"
                                            value={UnitOfMeasure}
                                            onChange={(e) => setUnitOfMeasure(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Description</label>
                                        <input
                                            type="text"
                                            value={Description}
                                            onChange={(e) => setDescription(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label> Product Image</label>
                                        <div className="image-upload">
                                            <input
                                                type="file"
                                                accept="image/*"
                                                disabled={ItemImage ? true : false}
                                                onChange={(e) => setItemImage(e.target.files[0])}
                                            />
                                            <div className="image-uploads">
                                                {ItemImage ?
                                                    <iconify-icon icon="streamline:interface-file-clipboard-check-checkmark-edit-task-edition-checklist-check-success-clipboard-form" style={{ "fontSize": "30px", "color": "orange" }} ></iconify-icon>
                                                    :
                                                    <img src={Upload} alt="img" />
                                                }
                                                <h4>Drag and drop a file to upload</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <button className="btn btn-submit me-2" onClick={ProductName && Price && handleProfileUpload} disabled={loading}>
                                        {loading ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><CircleSpinner size={20} color="black" loading={loading} /> </div> : "Submit"}
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /add */}
                </div>
            </div>
        </>
    )
}
export default AddNewItem;