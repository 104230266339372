import React, { useState,useEffect,useRef,useContext } from "react";
import { Navigate,Link} from 'react-router-dom';
import {Dash1,Dash2,Dash3,Dash4,Dropdown,} from "../EntryFile/imagePath";
import Select from 'react-dropdown-select';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import {Excel,Pdf,Printer} from "../EntryFile/imagePath";
import Table from "../EntryFile/datatables"
import Chart from "react-apexcharts";
import FeatherIcon from "feather-icons-react";
import CountUp from "react-countup";
import {FetchDashboardLayout,FetchDashboardData,FetchJsonFromS3,RecieptGenerator,FetchAppointmentDetailsForListing,guidedata_fetch_api,UpdateAppointmentDateChange} from "../api/Aws-api"
import { ConstructorEventGet ,ConstructorEventPost,FormSubmitEvent} from "../Event/ConstructorEvent";
import { ClassicSpinner,CircleSpinner } from "react-spinners-kit";
import { GetDate,FetchWorkingHours, errorMessage } from "./Functions/CommonFunctions";
import Tabletop from "../EntryFile/tabletop";
import html2canvas from 'html2canvas';
import { successMessage } from "./Functions/CommonFunctions";
import { ApplicationAttributes } from "../InitialPage/App";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "../assets/plugins/fullcalendar/fullcalendar.min.css"
import { Modal } from "react-bootstrap";


const Dashboard = () => {
  const [Data,setData] = useState([])
  const [selectyear,setSelectyear] = useState("2023")
  const [DashboardData,setDashboardData] = useState([])
  const [jsonData,setJsonData] = useState()
  const [loading,setLoading] = useState(false)
  const [refreshSpinnner,setRefreshSpinnner] = useState(false)
  const chartRef = useRef(null);
  const tableRefs = useRef([])
  const [events, setEvents] = useState([])
  const [Filteredevents, setFilteredEvents] = useState([])
  const [ChangedEventList, setChangedEventList] = useState([])
  const [SaveChanges,setSaveChanges] = useState(false)
  const [SaveChangesLoading,setSaveChangesLoading] = useState(false)
  const [salesPersonList, setSalesPersonList] = useState([])
  const [salesPerson,setSalesPerson] = useState()
  const workflow_code = localStorage.getItem('Workflow_code')
  const {OutletCode,B2BCurrencyCode,setHeaderLoading} = useContext(ApplicationAttributes)
  const [modalShow, setModalShow] = useState(false)
  const [ResheduleDateFrom,setResheduleDateFrom] = useState()
  const [ResheduleDateTo,setResheduleDateTo] = useState()

  useEffect(()=>{
    setLoading(true)
    console.log("woow")
    //fetch the Dashboard Layout whenever there is a WorkFlowCode in the Cookie
    if (workflow_code){FetchDataFromSession()}
    else {return <Navigate to={'/dream-pos/settingUp'}/>}
    //timeout for DashbardData
    const intervalID = setInterval(() => {
      fetchdata()
      console.log("Auto Refresh Initiated");
    }, 60 * 1000);
    // 👇️ Stop timeout when the we moved from the Dashboard
    return () => {clearTimeout(intervalID);};
    
  },[])

  const FetchDataFromSession = () => {
    const DashboardLayout = sessionStorage.getItem('DashboardLayout');
    const DashboardData = sessionStorage.getItem('DashboardData');
    const DashboardjsonData = sessionStorage.getItem('DashboardjsonData');
    if (DashboardLayout != "undefined" && DashboardLayout != "null" && DashboardLayout != undefined && DashboardLayout != null){setData(JSON.parse(DashboardLayout));setLoading(false)}
    if (DashboardData != "undefined" && DashboardData != "null" && DashboardData != undefined && DashboardData != null){setDashboardData(JSON.parse(DashboardData))}
    if (DashboardjsonData != "undefined" && DashboardjsonData != "null" && DashboardjsonData != undefined && DashboardjsonData != null){setJsonData(JSON.parse(DashboardjsonData))}
    FetchLayout()
  }

  async function FetchLayout(){
    setRefreshSpinnner(true)
    const LayoutData = await ConstructorEventGet(FetchDashboardLayout , {workflow_code : workflow_code,client_code: OutletCode});
    sessionStorage.setItem('DashboardLayout', JSON.stringify(LayoutData[0]))
    setData(LayoutData[0] ? LayoutData[0] : [])
    const isDataCalenderPresent = LayoutData[0].hasOwnProperty("data_calender");
    console.log(isDataCalenderPresent,"data_calenderPresent ? ")
    if (isDataCalenderPresent) {fetchDataOfAppointmentMenu()}
    else {fetchdata()}
    setLoading(false)
    }

  const fetchdata = async ()=>{
    setHeaderLoading(true)
    setRefreshSpinnner(true)
    const Dashboarddata = await ConstructorEventGet(FetchDashboardData ,{client_code: OutletCode});
    console.log(Dashboarddata,"dashboarddata")
    const transformedJson = Dashboarddata && Dashboarddata.reduce((result, item) => {
      result[item.widget_name] = {
        json_data: item.json_data ? item.json_data : null,
        widget_data: item.widget_data ? item.widget_data : null
      };
      return result;
    }, {});
    //setLoading(false)
    //setRefreshSpinnner(false)
    setDashboardData(transformedJson)
    sessionStorage.setItem('DashboardData', JSON.stringify(transformedJson))
    fetchJsondata(transformedJson)
  }

  const fetchJsondata = async(transformedJson) =>{
    let temp = []
    for (let data2 in transformedJson){
      if (transformedJson[data2].json_data){
      temp.push(transformedJson[data2].json_data.s3_json_path)
    }}
    const ResponseData = await ConstructorEventPost(FetchJsonFromS3,{fetchdata : temp , ClientCode : OutletCode});
    setJsonData(ResponseData)
    sessionStorage.setItem('DashboardjsonData', JSON.stringify(ResponseData))
    setRefreshSpinnner(false)
    setHeaderLoading(false)
  }

  const spinnerStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 9999,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const spinnerWrapperStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const handleYearChange = (event) => {
    setSelectyear(event.target.value);
    console.log(event.target.value)
  }
  const handleSalesPersonSales = (salesPersonData) => {
    setSalesPerson(salesPersonData)
    console.log(salesPersonData)
    if (salesPersonData === "ALL"){setFilteredEvents(events)}
    else {
      let newArray = []
      for (let eachevent in events){
        if (events[eachevent]["sales_person_id"] === salesPersonData){
          newArray.push(events[eachevent])
        }
      }
      setFilteredEvents(newArray)
    }
  }
  const fetchDataOfAppointmentMenu = async() => {
    if (!SaveChanges){
      setHeaderLoading(true)
      const responseData = await ConstructorEventGet(FetchAppointmentDetailsForListing,{"client_code" : OutletCode });
      SetOldAppointmentstocalender(responseData)
      const SalesPersonResponseData = await ConstructorEventPost(guidedata_fetch_api,{"tablename" : OutletCode +"_user"});
      let salespersonArray = SalesPersonResponseData.filter(data => data.User_type == "sales_person");
      setSalesPersonList(salespersonArray)
    }
    fetchdata()
  }

  const SetOldAppointmentstocalender = (data) => {
    let NewArray = []
    for (let i in data){
      const RawData = {
        title: data[i]["customer_name"],
        customer_id : data[i]["customer_id"],
        start: Date.parse(data[i]["start_time"].replace(" ", "T")),
        end: Date.parse(data[i]["end_time"].replace(" ", "T")),
        className: data[i]["appointment_status"] === "Sheduled" ? "bg-Coral" : data[i]["appointment_status"] === "InProgress" ? "bg-SoftLavender" : data[i]["appointment_status"] === "Completed" ? "bg-Teal" : "bg-warning",
        eventStatus: data[i]["appointment_status"],
        notes: data[i]["notes"],
        id: data[i]["appointment_id"],
        sales_person_id : data[i]["sales_person_id"],
        sales_person_name : data[i]["sales_person_name"]
      }
      console.log(RawData)
      NewArray.push(RawData)
    }
    setEvents(NewArray)
    setFilteredEvents(NewArray)
  }

  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.title}</b>
        <i>{eventInfo.event.title}</i>
      </>
    )
  }

  const ChartOptions = {
      colors: ["#28C76F", "#EA5455"],
      chart: {
        type: "bar",
        height: 300,
        stacked: true,
  
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 280,
          options: {
            legend: {
              position: "bottom",
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "20%",
          borderRadius: 5,
          borderRadiusTop: 5,
        },
      },
      xaxis: {
        categories: [
          " Jan ",
          "feb",
          "march",
          "april",
          "may",
          "june",
          "july",
          "auguest",
        ],
      },
      legend: {
        position: "top",
      },
      fill: {
        opacity: 1,
      },
  };
  const date = GetDate()
  var parts = date.split('/'); // split the string into an array of substrings
  const MonthAndYear = parts[1] + '/' + parts[2];
  const table_limit = 8
  ///////
  

  const GenerateReciept = async(WidgetName,data) => {
    setHeaderLoading(true)
    console.log(WidgetName,data)
    /*const html = `
            <html>
            <head>
              <title>${type}</title>
            </head>
            <body>
              <h1>SalesCounty</h1>
              <hr/>
              <h3>${type}</h3>
              <img src="${chartImage}" alt="Chart">
            </body>
          </html>
      `;
      console.log(html)
      const blob = new Blob([html], { type: 'text/html' });
      const url = URL.createObjectURL(blob);
      console.log(url)
      const link = document.createElement('a');
      link.href = url;
      link.download = 'chart.html';
      link.click();*/

    const responseData = await FormSubmitEvent(RecieptGenerator ,{"WidgetName": WidgetName, "WidgetData" : data });
    console.log(responseData)
    downloadPDFFromURL(responseData)
    setHeaderLoading(false)
  };
  const downloadPDFFromURL = async (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', '');
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
  const eventChanged = (data) =>{
    const event = data.event.toPlainObject()
    const temp = {
      "appointment_id" : event.id,
      "startTime" : ConvertToActualTimeFormat(event.start),
      "endTime" : ConvertToActualTimeFormat(event.end),
    }
    const tempArray = ChangedEventList
    const CheckDuplication = tempArray.findIndex(data => data.appointment_id === event.id)
    if (CheckDuplication !== -1){
      tempArray[CheckDuplication]["startTime"] = temp["startTime"]
      tempArray[CheckDuplication]["endTime"] = temp["endTime"]
    }
    else{tempArray.push(temp)}
    setChangedEventList(tempArray)
    setSaveChanges(true)
  }
  const ConvertToActualTimeFormat = (olddate) => {
    const date = olddate.split("+")[0].replace("T", " ")
    return date
  }
  const WorkingHours = FetchWorkingHours()

  const UpdateTheAppointmentDateChange = async(data) => {
    setSaveChangesLoading(true)
    const responseData = await FormSubmitEvent(UpdateAppointmentDateChange ,{"client_code": OutletCode, "appointmentChangeData" : data });
    if (responseData === "updation_Success"){setChangedEventList([]);setSaveChanges(false);fetchDataOfAppointmentMenu()}
    else(errorMessage("Something Went Wrong"))
    setSaveChangesLoading(false)
    setModalShow(false)
  }

  const ChangeTimestampToDesiredDate = (timestamp) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
    const data1 =  new Date(timestamp).toLocaleDateString('en-IN', options).split("/")
    return data1[2] + "-" + data1[1] + "-" + data1[0]
  }

  const ReSheduleEventsOfASpecificDay = () => {
    const tempArray = []
    const FetchTimeFromTimeStamp = (timeStamp) => {return new Date(timeStamp).toLocaleTimeString('en-IN', { hour12: false, hour: '2-digit', minute: '2-digit' }) + ":00"}
    const EventsOfThatSpecificDay = events.filter((data) => ChangeTimestampToDesiredDate(data.start) === ResheduleDateFrom)
    console.log(EventsOfThatSpecificDay)
    for (let eachevent of EventsOfThatSpecificDay){
      const temp = {
        "appointment_id" : eachevent.id,
        "startTime" : ResheduleDateTo + " " + FetchTimeFromTimeStamp(eachevent.start),
        "endTime" : ResheduleDateTo + " " + FetchTimeFromTimeStamp(eachevent.end),
      }
      tempArray.push(temp)
    }
    console.log(tempArray,"temArray")
    UpdateTheAppointmentDateChange(tempArray)
  }

  function ResheduleModal(props) {
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static" 
        animation={false}
        
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Reshedule Appointment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

              <div className="form-group">
                <label>Reshedule From : </label>
                  <input 
                      type="date" 
                      className='time-input'
                      value={ResheduleDateFrom}
                      onChange={(e) => setResheduleDateFrom(e.target.value)}
                  />
              </div>
              <div className="form-group">
                <label>Reshedule To : </label>
                  <input 
                      type="date" 
                      className='time-input'
                      value={ResheduleDateTo}
                      onChange={(e) => setResheduleDateTo(e.target.value)}
                  />
              </div>

        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={props.onHide} disabled={SaveChangesLoading}>Close</button>
          <button className="btn btn-submit" onClick={ResheduleDateFrom && ResheduleDateTo && ReSheduleEventsOfASpecificDay} >
          {SaveChangesLoading ?<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><CircleSpinner size={20} color ="white" loading={SaveChangesLoading}/> </div> : "Save Changes"}
            </button>
        </Modal.Footer>
      </Modal>
    );
  }
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          {
          loading ? 
          <div style={spinnerStyle}>
          <div style={spinnerWrapperStyle}>
          <ClassicSpinner size={50} color ="black" loading={loading} />
          </div></div>
           : null }

          <div>

          
            {/*Dash Calender*/}
            <div className="row">
            {Data.data_calender && Data.data_calender
              .sort((a, b) => a.order_of_presidence - b.order_of_presidence)
                .map((data1,index)=>(
                  <div key={index} className="col-lg-12 col-sm-12 col-12">
                    <div className="card flex-fill">
                    <ResheduleModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        />
                      <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                        <h4 className="card-title mb-0">{data1.alias_name}</h4>
                        {SaveChanges ? <button className="btn btn-save rounded" onClick={()=>UpdateTheAppointmentDateChange(ChangedEventList)}>{SaveChangesLoading ? <CircleSpinner size={20} color ="grey" loading={SaveChangesLoading} /> : "Save Changes"}</button> : ""}
                        <div className="graph-sets">
                          <div className="dropdown" >
                            <select className="form-control" value={salesPerson} onChange={(e)=>handleSalesPersonSales(e.target.value)}>
                              <option value="ALL">ALL</option>
                              {salesPersonList && salesPersonList.map((data, index) => (<option value={data["user_id"]}>{data["name"]}</option>))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div >
                        <div className="card-body row">
                          <div className="col-lg-12 col-md-12 ml-4 mr-8">
                            <FullCalendar
                              plugins={[dayGridPlugin,timeGridPlugin,interactionPlugin]}
                              headerToolbar={{
                                right: "prev,next,customButton today,dayGridMonth,timeGridWeek",
                              }}
                              customButtons= {{
                                customButton: {
                                  text: 'Reshedule',
                                  click: function() {
                                    // Custom button click event handler
                                    setModalShow(true)
                                  }
                                }
                              }}
                              initialView='dayGridMonth'
                              slotDuration="00:15:00"
                              slotMinTime={WorkingHours.split("&")[0]}
                              slotMaxTime={WorkingHours.split("&")[1]}
                              dayMaxEvents={true}
                              weekends={true}
                              selectable={true}
                              editable={true}
                              selectMirror={true}
                              events={Filteredevents}
                              eventContent={renderEventContent}
                              eventChange={(clickInfo) => eventChanged(clickInfo)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  ))}
          </div>
            {/*Dash Calender*/}

            {/*Dash Widget*/}
            <div className="row">
            {Data.dash_widget && Data.dash_widget
              .sort((a, b) => a.order_of_presidence - b.order_of_presidence)
                .map((data1,index)=>(
                  <div key={index} className="col-lg-3 col-sm-6 col-12">
                    <div className="dash-widget dash3">
                      <div className="dash-widgetimg">
                        <span>
                          <img src={Dash1} alt="img" />
                        </span>
                      </div>
                      <div className="dash-widgetcontent">
                        <h5>
                          {B2BCurrencyCode}
                          <span className="counters">
                            <CountUp end={DashboardData? DashboardData[data1.widget_name] ? DashboardData[data1.widget_name].widget_data ? DashboardData[data1.widget_name].widget_data[date] ? parseInt(DashboardData[data1.widget_name].widget_data[date]):0:0:0:0} />
                          </span>
                        </h5>
                        <h6>{data1.alias_name}</h6>
                      </div>
                    </div>
                  </div>
                  ))}
          </div>
            {/*Dash Widget*/}

          <div className="row">
            {Data.dash_count && Data.dash_count
                .sort((a, b) => a.order_of_presidance - b.order_of_presidance)
                  .map((data1,index)=>(

                    <div key={index} className="col-lg-3 col-sm-6 col-12 d-flex">
                      <div className="dash-count">
                        <div className="dash-counts">
                          <h4>{ DashboardData ? DashboardData[data1.widget_name]? DashboardData[data1.widget_name].widget_data ? DashboardData[data1.widget_name].widget_data[date] ? DashboardData[data1.widget_name].widget_data[date] :0:0:0:0}</h4>
                          <h5>{data1.alias_name}</h5>
                        </div>
                        <div className="dash-imgs">
                          <FeatherIcon icon="user" />
                        </div>
                      </div>
                  </div>
                    ))}
          </div>
          {/* dash_chart */}
          <div className="row">
            {Data.dash_chart && Data.dash_chart
                  .sort((a, b) => a.order_of_presidance - b.order_of_presidance)
                    .map((data1,index)=>(
                      <div key={index} className="col-lg-12 col-sm-12 col-12 d-flex">
                        <div className="card flex-fill">
                          <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                            <h5 className="card-title mb-0">{data1.alias_name}</h5>
                            <div className="graph-sets">
                              <div className="dropdown" >
                              <select className="form-control" value={selectyear} onChange={handleYearChange}>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                              </select>
                              </div>
                            </div>
                          </div>
                            <div className="card-body">
                              <div className="table-top">
                                <div className="search-set"> </div>
                                <div className="wordset">
                                  <ul>
                                    <ReactTooltip place="top" type="dark" effect="solid" />
                                    <li>
                                      <a data-tip="Pdf">
                                        <img src={Pdf} alt="img" onClick={()=>GenerateReciept(data1.widget_name,(jsonData ? jsonData[data1.widget_name+'.json'] ? jsonData[data1.widget_name+'.json'].series ? jsonData[data1.widget_name+'.json'].series: []:[]:[]))}/>
                                      </a>
                                    </li>
                                    <li>
                                      <a data-tip="Excel">
                                        <img src={Excel} alt="img" />
                                      </a>
                                    </li>
                                    <li>
                                      <a data-tip="Print">
                                        <img src={Printer} alt="img" />
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div ref={chartRef}>
                            <Chart
                              options={jsonData ? jsonData[data1.widget_name+'.json'] ? jsonData[data1.widget_name+'.json'].options ? jsonData[data1.widget_name+'.json'].options : ChartOptions :ChartOptions:ChartOptions}
                              series={jsonData ? jsonData[data1.widget_name+'.json'] ? jsonData[data1.widget_name+'.json'].series ? jsonData[data1.widget_name+'.json'].series: []:[]:[]}
                              type="bar"
                              height={250}
                            /></div>
                          </div>
                        </div>
                      </div>
                  ))}
          </div>
          {/* data tables */}
          <div className="row">
            {Data.data_table && Data.data_table
                  .sort((a, b) => a.order_of_presidance - b.order_of_presidance)
                    .map((data1,index)=>(
                      <div key={index} className="col-lg-12 col-sm-12 col-12 d-flex">
                        <div className="card flex-fill">
                          <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                            <h4 className="card-title mb-0">{data1.alias_name}</h4>
                          </div>
                          <div className="card-body">
                          <div className="table-top">
                                <div className="search-set"> </div>
                                <div className="wordset">
                                  <ul>
                                    <ReactTooltip place="top" type="dark" effect="solid" />
                                    <li>
                                      <a data-tip="Pdf">
                                        <img src={Pdf} alt="img" onClick={()=>GenerateReciept(data1.widget_name,(jsonData ? jsonData[data1.widget_name+'.json'] !== undefined ? jsonData[data1.widget_name+'.json'][MonthAndYear] : null : null))}/>
                                      </a>
                                    </li>
                                    <li>
                                      <a data-tip="Excel">
                                        <img src={Excel} alt="img" />
                                      </a>
                                    </li>
                                    <li>
                                      <a data-tip="Print">
                                        <img src={Printer} alt="img" />
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            <div className="table-responsive dataview"  ref={(ref) => (tableRefs.current[index] = ref)}>
                              <Table                      
                                columns={(jsonData&&jsonData[data1.widget_name+'.json']?.columns) ?? null}
                                dataSource={jsonData ? jsonData[data1.widget_name+'.json'] !== undefined ? jsonData[data1.widget_name+'.json'][MonthAndYear] : null : null}
                                pagination={jsonData ? jsonData[data1.widget_name+'.json'] !== undefined ? jsonData[data1.widget_name+'.json'][MonthAndYear]!== undefined ?jsonData[data1.widget_name+'.json'][MonthAndYear].length > table_limit ? { pageSize: table_limit} : false : false : false : false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
            </div> 
          </div>
          <div 
          className="refreshButton"
           onClick={fetchdata}>
              <div className="refreshButtonInside">
                {refreshSpinnner ? 
                <CircleSpinner size={20} color ="orange" loading={refreshSpinnner} /> :
                <iconify-icon icon="ic:baseline-refresh" style={{ color: 'orange', fontSize: '25px' }} ></iconify-icon>}
              </div>
            </div> 
        </div>
      </div>
    </>
  );
};

export default Dashboard;
