import React, { useState , useEffect,useContext } from "react";
import { Link ,useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Table } from "antd";
import Tabletop from "../../EntryFile/tabletop"
import {PlusIcon,EditIcon,DeleteIcon} from "../../EntryFile/imagePath";
import { FetchTotalItem,CloudFrontDistributionForImageFetch,UpdateItemDetails } from "../../api/Aws-api";
import { ConstructorEventGet,ConstructorEventPost } from "../../Event/ConstructorEvent";
import { ClassicSpinner } from "react-spinners-kit";
import { ApplicationAttributes } from "../../InitialPage/App";
import { errorMessage,successMessage } from "../Functions/CommonFunctions";
import { Search} from "../../EntryFile/imagePath";


const ItemList = () => {
  const [inputfilter, setInputfilter] = useState(false); 
  const [ItemList, setItemList] = useState([]); 
  const [FilteredItemList,setFilteredItemList] = useState([])
  const [SearchInput,setSearchInput] = useState("")
  const [loading,setLoading] = useState(false)
  const {OutletCode} = useContext(ApplicationAttributes) 
  const [Deleteloading,setDeleteloading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    fetchdata()
  },[])

  const  fetchdata = async() => {
    const responseData = await ConstructorEventGet(FetchTotalItem ,{client_code: OutletCode});
    setItemList(responseData ? responseData.errorMessage ? [] : responseData : [])
    setFilteredItemList(responseData ? responseData.errorMessage ? [] : responseData : [])
    setLoading(false)
  }

  const togglefilter = (value) => {    
    setInputfilter(value);  
  };
  const confirmText = (Item_data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(function (t) {
      t.value && DeleteAnItem (Item_data)
    });
  };

  
  const columns = [
    {
      title: "Item Name",
      dataIndex: "item_name",
      render: (text, record) => (
        <div className="productimgname">
            {record && record.image_url ? <img alt="" style={{ width: "30px", height: "30px" }} src={CloudFrontDistributionForImageFetch + record.image_url} /> : ""}
          <div  style={{  marginLeft: "10px",fontWeight : "500" }}>
            {record.item_name}
          </div>
        </div>
      ),
      sorter: (a, b) => a.item_name.length - b.item_name.length,
    },
    {
      title: "Category",
      dataIndex: "catogory",
      sorter: (a, b) => a.catogory.length - b.catogory.length,
    },
    {
      title: "Price",
      dataIndex: "price",
      sorter: (a, b) => a.price.length - b.price.length,
    },
    {
      title: "Action",
      render: (record) => (
        <>
          <>
            <Link className="me-3" to="/dream-pos/item/EditItem" state ={{data:record }}>
              <img src={EditIcon} alt="img" />
            </Link>
            <Link className="confirm-text" to="#" onClick={()=>confirmText(record)}>
              <img src={DeleteIcon} alt="img" />
            </Link>
          </>
        </>
      ),
    },
  ];

  const DeleteAnItem = async (Item_data) => {
    setLoading(true)
    const responsedata = await ConstructorEventPost(UpdateItemDetails,{params : Item_data,delete : true,clientCode : OutletCode})
    if (responsedata === "deleted"){successMessage("Item Deleted");navigate("/dream-pos/item/ListItem");fetchdata()}
    else {errorMessage("Item Deletion Failed")}
    setLoading(false)
  }

  const HandelSearch = (searchdata) => {
    const searchInput = searchdata.toLowerCase();
    setSearchInput(searchInput);
    // Filter the data source based on search input value
    const filtered = ItemList.filter(record =>(record.item_name.toLowerCase().includes(searchInput)||record.item_code.toLowerCase().includes(searchInput)||record.catogory.toLowerCase().includes(searchInput)));
    setFilteredItemList(filtered);
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Item List</h4>
              <h6>Manage your Items</h6>
            </div>
            <div className="page-btn">
              <Link
                to="/dream-pos/item/AddNewItem"
                className="btn btn-added"
              >
                <img src={PlusIcon} alt="img" className="me-1" />
                Add New Item
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      className="form-control form-control-sm search-icon"
                      type="text"
                      placeholder="Search..."
                      value={SearchInput}
                      onChange={(e)=>HandelSearch(e.target.value)}
                    />
                    <div className="btn btn-searchset">
                      <img src={Search} alt="img" />
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="table-responsive">
                <Table                                    
                  columns={columns}
                  dataSource={FilteredItemList} 
                  rowKey={(record) => record.item_code}
                  loading={{ indicator: <div><ClassicSpinner size={50} color ="black" loading={loading} /> </div>, spinning: loading}}                  
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </>
  );
};
export default ItemList;
