import Dashboard from "../MainPage/Dashboard";
import Session from '../MainPage/Session';
import {Settings_Index} from "../MainPage/Settings";
import { Outlet_Index } from "../MainPage/Outlet";
import { Item_Index } from "../MainPage/Item";

export const routerService = [
    {
        path: 'dashboard',
        component: Dashboard
    },
    {
        path:'session',
        component: Session
    },
    {
        path:'settings',
        component: Settings_Index
    },
    {
        path:'outlet',
        component: Outlet_Index
    },
    {
        path:'item',
        component: Item_Index
    }
]
