import React from 'react';

const StockManagement = () => {
    return (
        <div>
            
        </div>
    );
}

export default StockManagement;
