import React, { useState, useRef, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { ForwardUriFetchFunction } from '../../Functions/CommonFunctions';
import { SalesAttributes } from '../index';
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import { hello } from "../../../EntryFile/imagePath";

const EditInspectionReportImage = () => {
    const [Images, setImages] = useState([]);
    const [WatermarkImage, setWatermarkImage] = useState([]);
    const [watermark, setwatermark] = useState([]);
    const [ImageSelect, setImageSelect] = useState(null);
    const [SignatureSelect, setSignatureSelect] = useState(null);
    const [watermarkselect, setWatermarkselect] = useState(null);
    const [OrderOfExecution, setOrderOfExecution] = useState();
    const loc = useLocation();
    const navigate = useNavigate();
    const uri = useRef(null);
    const { InitiatorCode, setInspectionReportImage, setInspectionReportSignatureImage, setInspectionReportwatermarkImage } = useContext(SalesAttributes);

    useEffect(() => {
        const { order_of_execution } = loc.state ? loc.state : {};
        if (order_of_execution) {
            setOrderOfExecution(order_of_execution);
            uri.current = ForwardUriFetchFunction(order_of_execution, InitiatorCode);
        }
    }, [loc.state, InitiatorCode]);

    const addNewImage = () => {
        const fileInput = document.getElementById('fileInputImages');
        fileInput.click();
    };

    const addNewSignature = () => {
        const fileInput = document.getElementById('fileInputSignatures');
        fileInput.click();
    };

    // const addNewWatermark =()=>{
    //     const fileInput = document.getElementById('ferrari');
    //     fileInput.click();
    // };

    const removeImage = () => {
        if (ImageSelect !== null) {
            const tempImages = [...Images];
            tempImages.splice(ImageSelect, 1);
            setImages(tempImages);
            setImageSelect(null);
        }
    };

    const removeSignature = () => {
        if (SignatureSelect !== null) {
            const tempWatermarkImages = [...WatermarkImage];
            tempWatermarkImages.splice(SignatureSelect, 1);
            setWatermarkImage(tempWatermarkImages);
            setSignatureSelect(null);
        }
    };

    // const removewatermark =()=>{
    //     if (watermarkselect !== null){
    //         const tempwater = [...watermark];
    //         tempwater.splice(watermarkselect, 1);
    //         setwatermark(tempwater);
    //         setWatermarkselect(null);
    //     }
    // }

    const handleImageSelect = (index) => {
        setImageSelect(index);
        setSignatureSelect(null);
    };

    const handleSignatureSelect = (index) => {
        setSignatureSelect(index);
        setImageSelect(null);
    };

    // const handlewatermarkselect = (index) => {
    //     setWatermarkselect(index);
    //     setWatermarkselect(null);
    // };

    const handleImageChange = (e) => {
        const temp = Array.from(e.target.files);
        setImages([...Images, ...temp]);
    };

    const handleSignatureChange = (e) => {
        const temp = Array.from(e.target.files);
        setWatermarkImage([...WatermarkImage, ...temp]);
    };

    // const handlewatermarkchange =(e)=>{
    //     const temp = Array.from(e.target.files);
    //     setwatermark([...watermark, ...temp])
    // };

    const onClickNext = () => {
        setInspectionReportImage(Images);
        setInspectionReportSignatureImage(WatermarkImage);
        setInspectionReportwatermarkImage(watermark);
        navigate(uri.current, { state: { order_of_execution: OrderOfExecution + 1 } });
    };

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="page-title">
                        <h4>Add Images</h4>
                    </div>
                    <div className="page-btn">
                        <button className="btn btn-added" onClick={onClickNext}>
                            Next
                        </button>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="pb-4">
                            <div className="image-container-inspection-content-addNew" onClick={addNewImage}>
                                <iconify-Icon icon="akar-icons:plus" style={{ paddingTop: "2px" }} /> Add New Image
                            </div>
                            {ImageSelect !== null && (
                                <div className="image-container-inspection-content-removeButton" onClick={removeImage}>
                                    <iconify-Icon icon="eva:close-fill" style={{ paddingTop: "2px" }} /> Remove Image
                                </div>
                            )}
                        </div>
                        <div className="row">
                            {Images.map((image, index) => (
                                <div className="col-lg-3 col-sm-6 col-12 pb-4" key={index} onClick={() => handleImageSelect(index)}>
                                    <div className={ImageSelect === index ? 'image-container-inspection-image-remove' : 'image-container-inspection'}>
                                        <img src={URL.createObjectURL(image)} alt={`Image ${index}`} style={{ borderRadius: "10px" }} />
                                    </div>
                                </div>
                            ))}
                            <input
                                type="file"
                                id="fileInputImages"
                                multiple
                                accept="image/*"
                                onChange={handleImageChange}
                                style={{ display: 'none' }}
                            />
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="pb-4">
                            <div className="image-container-inspection-content-addNew" onClick={addNewSignature}>
                                <iconify-Icon icon="akar-icons:plus" style={{ paddingTop: "2px" }} /> Add Signature
                            </div>
                            {SignatureSelect !== null && (
                                <div className="image-container-inspection-content-removeButton" onClick={removeSignature}>
                                    <iconify-Icon icon="eva:close-fill" style={{ paddingTop: "2px" }} /> Remove Signature
                                </div>
                            )}
                        </div>
                        <div className="row">
                            {WatermarkImage.map((image, index) => (
                                <div className="col-lg-3 col-sm-6 col-12 pb-4" key={index} onClick={() => handleSignatureSelect(index)}>
                                    <div className={SignatureSelect === index ? 'image-container-inspection-image-remove' : 'image-container-inspection'}>
                                        <img src={URL.createObjectURL(image)} alt={`Signature ${index}`} style={{ borderRadius: "10px" }} />
                                    </div>
                                </div>
                            ))}
                            <input
                                type="file"
                                id="fileInputSignatures"
                                multiple
                                accept="image/*"
                                onChange={handleSignatureChange}
                                style={{ display: 'none' }}
                            />
                        </div>
                    </div>
                </div>
                {/* <div className="card">
                    <div className="card-body">
                        <div className="pb-4">
                            <div className="image-container-inspection-content-addNew" onClick={addNewWatermark}>
                                <iconify-Icon icon="akar-icons:plus" style={{ paddingTop: "2px" }} /> Add Watermark
                            </div>
                            {watermarkselect !== null && (
                                <div className="image-container-inspection-content-removeButton" onClick={removewatermark}>
                                    <iconify-Icon icon="eva:close-fill" style={{ paddingTop: "2px" }} /> Remove Signature
                                </div>
                            )}
                        </div>
                        <div className="row">
                            {watermark.map((image, index) => (
                                <div className="col-lg-3 col-sm-6 col-12 pb-4" key={index} onClick={() => handlewatermarkselect(index)}>
                                    <div className={watermarkselect === index ? 'image-container-inspection-image-remove' : 'image-container-inspection'}>
                                        <img src={URL.createObjectURL(image)} alt={Signature ${index}} style={{ borderRadius: "10px" }} />
                                    </div>
                                </div>
                            ))}
                            <input
                                type="file"
                                id="ferrari"
                                multiple
                                accept="image/*"
                                onChange={handlewatermarkchange}
                                style={{ display: 'none' }}
                            />
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default EditInspectionReportImage;