import React, { useState, useRef, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { ForwardUriFetchFunction, GetDate, SimpleSwalNotification, successMessage, FetchOutletImage } from '../../Functions/CommonFunctions';
import { SalesAttributes } from '../index';
import { Table, Tooltip } from 'antd';
import { useReactToPrint } from 'react-to-print';
import { ApplicationAttributes } from "../../../InitialPage/App";
import { FormSubmitEvent, uploadImage } from '../../../Event/ConstructorEvent';
import { CreateNewInspectionReport, CloudFrontDistributionForOutletDataFetch } from '../../../api/Aws-api';
import { CircleSpinner } from 'react-spinners-kit';
import { hello, downloadlogo, inca, new_logo } from "../../../EntryFile/imagePath";


const EditInspectionReportReview = () => {
    const [OrderOfExecution, setOrderOfExecution] = useState();
    const [textareaColors, setTextareaColors] = useState({});
    const loc = useLocation()
    const navigate = useNavigate()
    const uri = useRef(null);
    const { InitiatorCode, InspectionReportHeaderData, InspectionReportTableData, InspectionReportImage, InspectionReportCommentsData, InspectionReportId, setInspectionReportId, InspectionReportSignatureImage, setInspectionReportSignatureImage } = useContext(SalesAttributes)
    const [loading, setLoading] = useState(false)
    const { OutletCode, B2BName } = useContext(ApplicationAttributes)
    const CurrentDate = GetDate()
    const OutletHeaderImage = FetchOutletImage()
    const [waterMarkImage, setWaterMarkImage] = useState("")

    const [selectedColor, setSelectedColor] = useState("");

    useEffect(() => {
        // Fetch color data from localStorage or any other source
        let colorData = localStorage.getItem("textareaColors");
        if (colorData) {
            // Parse color data (assuming it's stored as JSON)
            colorData = JSON.parse(colorData);
            // Update the textareaColors state
            setTextareaColors(colorData);
        }

        const result = localStorage.getItem("WaterMarkImage")

        setWaterMarkImage(result)
        console.log(`reportreview is ${result}`)
    }, []);


    console.log('the name of the file is ' + JSON.stringify(waterMarkImage))
    console.log(`the selected cccc is ${selectedColor}`)

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: '@media print {body {-webkit-print-color-adjust: exact;margin-top: 200mm;}@page {size: A4;margin-top: 10mm;margin-bottom: 10mm;}#header {content: none; }#footer {display: none;}}        '
    });

    const HeaderData = InspectionReportHeaderData
    const columns = [
        { dataIndex: "name" },
        {
            dataIndex: 'data',
            ellipsis: true, // Enable ellipsis
            render: (text) => <div>{text}</div>,
        }
    ];
    const TableData = InspectionReportTableData
    // const Inspectiontitle = InspectionReportTitle
    // const TableNotice = InspectionReportNotice
    const ImageData = InspectionReportImage
    const ImagesignData = InspectionReportSignatureImage
    const CommentData = InspectionReportCommentsData
    //const DivideSentenceintoPieces = (data) => { return data.split(".").filter((item) => item !== "") }
    const DivideSentenceintoPieces = (data) => {
        return data.split(".").filter((item) => item.trim() !== ""); // Split sentences based on newline characters
    };
    const [isNewPage, setIsNewPage] = useState(true);

    const SendWarningNotification = async () => {
        const messageHeading = "Are You Sure ?"
        const MessageBody = "You can't change the Report after Printing"
        const response = await SimpleSwalNotification(messageHeading, MessageBody)
        return response
    }
    // console.log(`the titile is ${Inspectiontitle}`)

    const addInspectionReportDatatoCloud = async () => {
        if (InspectionReportId === "") {
            const response = await SendWarningNotification()
            console.log(response)
            if (response) {
                setLoading(true)
                // handlePrint()
                const FileNames = ImageData.map((file) => ({ name: file.name, type: file.type }))
                const formData = {
                    "clientCode": OutletCode,
                    "ImageFileNames": FileNames,
                    "params": {
                        "HeaderData": InspectionReportHeaderData,
                        "TableData": InspectionReportTableData,

                        "CommentData": InspectionReportCommentsData,
                        "InspectionReportDate": CurrentDate,
                        "TemplateType": "Inspection"
                    }
                };
                const responseData = await FormSubmitEvent(CreateNewInspectionReport, formData);
                //const PDFData = await handleConvertToPdf()
                //const element = componentRef.current;
                //const pdf = new html2pdf();
                let flag = true
                for (let FileUrldata of responseData) {
                    const foundFile = ImageData.find((file) => file.name === FileUrldata["fileName"])
                    const response = await uploadImage(FileUrldata["uploadUrl"], foundFile)
                    if (response["status"] == 200) { console.log(FileUrldata["fileName"], "done") }
                    else { flag = false }
                }
                if (flag) { successMessage("Inpection Report Saved SuccesFully") }
                navigate(uri.current, { state: { order_of_execution: OrderOfExecution + 1 } })
            }
        }
        else {
            setLoading(true)
            handlePrint()
            navigate("/dream-pos/session/list_inspection")
            setInspectionReportId("")
        }
        handlePrint()
        setLoading(false)
    }
    console.log((waterMarkImage))
    return (
        <div className="page-wrapper">
            <div className="content" ref={componentRef}>

                <div className="card">
                    <div className="card-body">
                        <div className="row ml-4" >
                            <div className="col-12 mt-4 InspectionReviewHeaderDisplay ">
                                <div className="col-lg-3 col-sm-3 col-3 pb-4 " >
                                    <div>
                                        {OutletHeaderImage ? <img className='ml-4' src={CloudFrontDistributionForOutletDataFetch + OutletHeaderImage}
                                            alt={Image}
                                            style={{ borderRadius: "5px", maxHeight: "100px", maxWidth: "200px" }}
                                        /> :
                                            <div className='repeatheader' >
                                                <img src={downloadlogo} alt='' />
                                                <img src={inca} alt='' style={{ marginLeft: "505px" }} />
                                            </div>
                                        }
                                        <div className="col-12 me-5 ">

                                            {HeaderData.length > 0 && <Table
                                                className="headingstyle"
                                                columns={columns}

                                                dataSource={[
                                                    { data: HeaderData[0].data }
                                                ]}

                                                pagination={false}
                                                rowClassName={() => "single-line-row"}
                                            />
                                            }

                                        </div>

                                    </div>

                                </div>
                                <h9 style={{ marginLeft: 540, top: 400, fontWeight: 900 }} className='mr-8 strong'>{CurrentDate}</h9>

                                {HeaderData.map((item, index) => (
                                    <div className='row' key={index}>
                                        <div className='col-lg-3 col-sm-3 col-12'>{item.name}</div>
                                        <div className='col-lg-9 col-sm-9 col-12'>: {item.data}</div>
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                        <div className="row ml-4" >

                            <hr className='hrBolder mt-4'></hr>

                            <div className="col-12 mt-4">
                                {TableData.length > 0 && <Table
                                    className="table datanew dataTable no-footer"
                                    columns={columns}
                                    dataSource={TableData}
                                    pagination={false}

                                />
                                }
                            </div>
                            <div className='col-12 mt-4 row'>


                            </div>
                            <hr className='hrBolder mt-4'></hr>

                            <div className="col-12 mt-4">
                                {CommentData.map((item, index) => (
                                    <div key={index}>
                                        <div className='InspectionReviewCommentHeader' >{item.name} :</div>
                                        {DivideSentenceintoPieces(item.data).map((data, dataIndex) => (
                                            <div className='InspectionReviewCommentBody' style={{ paddingTop: isNewPage ? '50px' : '0', color: item.color, fontSize: item.fontSize, fontWeight: item.fontWeight }}>
                                                {data}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>

                            <div className="col-12 mt-4" >
                                <hr className='hrBolder mt-4'></hr>
                                {
                                    InspectionReportId === "" ?
                                        ImageData.map((image, index) => (
                                            <div className="col-lg-3 col-sm-3 col-3 pb-4" >
                                                <div className='image-container-inspection'>
                                                    <img
                                                        src={URL.createObjectURL(image)}
                                                        key={index}
                                                        alt={`Image ${index}`}
                                                        style={{ borderRadius: "10px" }}
                                                    />

                                                </div>
                                            </div>
                                        )) : ""}

                                <div className="inspectionPdfHeader">

                                    {
                                        InspectionReportId === "" ?
                                            ImagesignData.map((image, index) => (
                                                <div className="col-lg-3 col-sm-3 col-3 pb-4" >
                                                    <div className='image-container-inspection'>
                                                        <img
                                                            src={URL.createObjectURL(image)}
                                                            key={index}
                                                            alt={`Image ${index}`}
                                                            style={{ borderRadius: "10px" }}
                                                        />

                                                    </div>
                                                </div>
                                            )) : ""}
                                </div>
                                <h8 style={{ bottom: 40, marginLeft: -5 }}>(Signature of Engineer)</h8>
                                <h2 className='ml-4'> {B2BName}</h2>

                            </div>
                        </div>
                        <div className='watermark '>
                            {waterMarkImage ?
                                <img src={'https://d3gsquanna8qxt.cloudfront.net/' + (waterMarkImage)} alt='' /> : null
                            }</div>
                    </div>
                </div>
            </div>
            <div className="row ml-4">
                <hr className='hrBolder mt-4'></hr>
                <div className="col-lg-12 mt-4">
                    <button className="btn btn-cancel me-2" onClick={() => navigate("/dream-pos/session/list_inspection")}>
                        Dashboard
                    </button>
                    <button className="btn btn-submit " onClick={addInspectionReportDatatoCloud}>
                        {loading ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><CircleSpinner size={20} color="black" loading={loading} /> </div> : "Print"}
                    </button>
                </div>
            </div>

        </div >

    );
}


export default EditInspectionReportReview;