import React, { useEffect, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Dashboard,
  Expense,
  People,
  Places,
  Product,
  Time,
  Users1,
  settings,
  Purchase,
  Quotation,
  Return,
  Transfer,
  Sales1,
} from "../../EntryFile/imagePath";
import { Scrollbars } from "react-custom-scrollbars-2";
import FeatherIcon from "feather-icons-react";
import { ApplicationAttributes } from "../App";
import { WorkFlowSpecificNameChanger } from "../../MainPage/Functions/CommonFunctions";

const Sidebar = (props) => {
  const [isSideMenu, setSideMenu] = useState("");
  const workflowCode = localStorage.getItem('Workflow_code')
  const ActiveSession = JSON.parse(localStorage.getItem('ActiveSession'))
  const MatchingSession = JSON.parse(localStorage.getItem('MatchingSession'))
  const Session = JSON.parse(localStorage.getItem('session'))
  const Inspection = JSON.parse(localStorage.getItem('inspection'))
  const inspectionReport = JSON.parse(localStorage.getItem('inspectionReport'))
  const Training = JSON.parse(localStorage.getItem('training'))
  const { B2BUser } = useContext(ApplicationAttributes)
  const navigate = useNavigate()

  const toggleSidebar = (value) => {
    setSideMenu(value);
  };
  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };
  const pageRefresh = (url, page) => {
    navigate(`/dream-pos/${url}/${page}`);
    window.location.reload();
  };

  let pathname = window.location.pathname;
  useEffect(() => {
    document.querySelector(".main-wrapper").classList.remove("slide-nav");
    document.querySelector(".sidebar-overlay").classList.remove("opened");
    document.querySelector(".sidebar-overlay").onclick = function () {
      this.classList.remove("opened");
      document.querySelector(".main-wrapper").classList.remove("slide-nav");
    };
  }, [pathname]);
  return (
    <div className="sidebar" id="sidebar">
      <Scrollbars>
        <div className="sidebar-inner slimscroll">
          <div
            id="sidebar-menu"
            className="sidebar-menu"
            onMouseLeave={expandMenu}
            onMouseOver={expandMenuOpen}
          >
            <ul>
              <li className={pathname.includes("dashboard") ? "active" : ""}>
                <Link
                  to="/dream-pos/session/list_inspection"
                  onClick={() => toggleSidebar(isSideMenu == "" ? "" : "")}
                >
                  <img src={Dashboard} alt="img" />
                  <span>Dashboard</span>
                </Link>
              </li>

              {/* <li className={pathname.includes("session") ? "active" : ""}>
                  <Link
                    to="/dream-pos/session"
                    onClick={() => toggleSidebar(isSideMenu == "" ? "" : "")}
                  >
                    <img src={People} alt="img" />
                    <span>Session</span>
                  </Link>
                </li>*/}

              <li className="submenu">
                <a
                  href="#"
                  className={
                    pathname.includes("/dream-pos/session")
                      ? "active subdrop"
                      : "" || isSideMenu == "session"
                        ? "subdrop active"
                        : ""
                  }

                  onClick={() => { toggleSidebar(isSideMenu == "session" ? "" : "session") }}
                >
                  <img src={Transfer} alt="img" />
                  <span> Initiator </span> <span className="menu-arrow" />
                </a>
                {workflowCode ?
                  isSideMenu == "session" ? (
                    <ul>
                      {Session ?
                        <li>
                          <Link
                            className={
                              pathname.includes("session") && !pathname.includes("activesession") && !pathname.includes("training")
                                && !pathname.includes("matching_module") && !pathname.includes("inspection") ? "active" : ""
                            }
                            to="/dream-pos/session"
                            state={{ "initiator": "session" }}
                          >
                            Start Session
                          </Link>
                        </li> : ""}
                      {Inspection ?
                        <li>
                          <Link
                            className={
                              pathname.includes("session/inspection") && !pathname.includes("session/inspection_") ||
                                pathname.includes("session/edit_inspection") ? "active" : ""
                            }
                            to="/dream-pos/session"
                            state={{ "initiator": "inspection" }}
                          >
                            Start Inspection
                          </Link>
                        </li> : ""}
                      {Training ?
                        <li>
                          <Link
                            className={
                              pathname.includes("session/training") ? "active" : ""
                            }
                            to="/dream-pos/session"
                            state={{ "initiator": "training" }}
                          >
                            Start Training
                          </Link>
                        </li> : ""}
                      {inspectionReport ?
                        <li>
                          <Link
                            className={
                              pathname.includes("session/inspection_report_") ? "active" : ""
                            }
                            to="/dream-pos/session"
                            state={{ "initiator": "inspectionReport" }}
                          >
                            Generate Template
                          </Link>
                        </li> : ""
                      }
                      {Inspection ? <li>
                        <Link
                          to="/dream-pos/session/list_inspection"
                          className={
                            pathname.includes("list_inspection") ? "active" : ""
                          }
                        >
                          List Inspection
                        </Link>
                      </li> : ""}
                      {B2BUser && Inspection ? <li>
                        <Link
                          to="/dream-pos/session/pull_inspection_template"
                          className={
                            pathname.includes("pull_inspection_template") ? "active" : ""
                          }
                        >
                          Pull Inspection Templates
                        </Link>
                      </li> : ""}
                      {Session && ActiveSession ? <li>
                        <Link
                          to="/dream-pos/session/activesession"
                          className={
                            pathname.includes("activesession") ? "active" : ""
                          }
                        >
                          Active Session
                        </Link>
                      </li> : ""}
                      {Session && MatchingSession ? <li>
                        <Link
                          to="/dream-pos/session/matching_module"
                          className={
                            pathname.includes("matching_module") ? "active" : ""
                          }
                        >
                          Matching
                        </Link>
                      </li> : ""}

                    </ul>
                  ) : (
                    ""
                  ) : ""}
              </li>
              {B2BUser ?
                <li className="submenu">
                  <a
                    href="#"
                    className={
                      pathname.includes("/dream-pos/outlet")
                        ? "active subdrop"
                        : "" || isSideMenu == "outlet"
                          ? "subdrop active"
                          : ""
                    }

                    onClick={() => { toggleSidebar(isSideMenu == "outlet" ? "" : "outlet") }}
                  >
                    <img src={Places} alt="img" />
                    <span> {WorkFlowSpecificNameChanger("outlet")} </span> <span className="menu-arrow" />
                  </a>
                  {
                    isSideMenu == "outlet" ? (
                      <ul>
                        <li>
                          <Link
                            className={
                              pathname.includes("addoutlet") ? "active" : ""
                            }
                            to="/dream-pos/outlet/addoutlet"
                          >
                            Add New {WorkFlowSpecificNameChanger("outlet")}
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              pathname.includes("listoutlet") ? "active" : ""
                            }
                            to="/dream-pos/outlet/listoutlet"
                          >
                            List {WorkFlowSpecificNameChanger("outlet")}
                          </Link>
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                </li>
                : ""}
              <li className="submenu">
                <a
                  href="#"
                  className={
                    pathname.includes("/dream-pos/item")
                      ? "active subdrop"
                      : "" || isSideMenu == "item"
                        ? "subdrop active"
                        : ""
                  }

                  onClick={() => { toggleSidebar(isSideMenu == "item" ? "" : "item") }}
                >
                  <img src={Product} alt="img" />
                  <span> Inventory </span> <span className="menu-arrow" />
                </a>
                {workflowCode ?
                  isSideMenu == "item" ? (
                    <ul>
                      <li>
                        <Link
                          className={
                            pathname.includes("Catogory") ? "active" : ""
                          }
                          to="/dream-pos/item/ListCatogory"
                        >
                          Category
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            pathname.includes("Item") ? "active" : ""
                          }
                          to="/dream-pos/item/ListItem"
                        >
                          Item
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          className={
                            pathname.includes("stockManagement") ? "active" : ""
                          }
                          to="/dream-pos/item/stockManagement"
                        >
                          Stock Management
                        </Link>
                      </li> */}
                    </ul>
                  ) : (
                    ""
                  ) : ""}
              </li>
              <li className={pathname.includes("settings") ? "active" : ""}>
                <Link
                  to="/dream-pos/settings"
                  onClick={() => toggleSidebar(isSideMenu == "" ? "" : "")}
                >
                  <img src={settings} alt="img" />
                  <span>Settings</span>
                </Link>
              </li>

            </ul>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

export default Sidebar;
