import React, { useState, useRef, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { ForwardUriFetchFunction } from '../../Functions/CommonFunctions';
import { SalesAttributes } from '../index';
import { SaveWatermarkImage } from '../../../api/Aws-api';
import axios from "axios";
import { ConstructorEventPost } from '../../../Event/ConstructorEvent'
import { ApplicationAttributes } from "../../../InitialPage/App";


const InspectionReportTable = () => {
    const [AddRow, setAddRow] = useState(false)
    const [watermark, setwatermark] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userdata, setUserData] = useState();
    const [profileImage, setProfileImage] = useState("");

    const [WatermarkImage, setWatermarkImage] = useState([]);
    const [waterMarkImagetrain, setWaterMarkImagetrain] = useState([]);
    const [watermarkselect, setWatermarkselect] = useState(null);
    const [NewRawName, setNewRawName] = useState("")
    const [TableData, setTableData] = useState([{ "name": "Location", "data": "" }])

    const [OrderOfExecution, setOrderOfExecution] = useState();
    const loc = useLocation()
    const navigate = useNavigate()
    const uri = useRef(null);

    const { InitiatorCode, setInspectionReportTableData, InspectionReportwatermarkImage, setInspectionReportwatermarkImage } = useContext(SalesAttributes)

    useEffect(() => {
        const { order_of_execution } = loc.state ? loc.state : {};
        if (order_of_execution) { setOrderOfExecution(order_of_execution); uri.current = ForwardUriFetchFunction(order_of_execution, InitiatorCode) }
    })

    const CreateNewTableDataRaw = () => {
        const temp = [...TableData]
        let Flag = false
        for (let data of temp) { if (data.name === NewRawName) { Flag = true } }
        if (!Flag && NewRawName.length > 0) {
            setTableData([...TableData, { "name": NewRawName, "data": "" }])
            setNewRawName("")
        }
        setNewRawName("")
        setAddRow(false)
    }

    const DeleteRawTableDataRAw = (RawName) => {
        const temp = [...TableData]
        for (let data of temp) {
            if (data.name === RawName) {
                temp.splice(temp.indexOf(data), 1)
            }
        }
        setTableData(temp)
    }

    const HandleDataChange = (value, RawName) => {
        const temp = [...TableData]
        for (let data in temp) {
            if (temp[data]["name"] === RawName) {
                temp[data].data = value
            }
        }
        setTableData(temp)
    }

    const handlewatermarkUpload = async (e) => {
        setLoading(true);
        const files = e.target.files;
        setProfileImage(files[0]);
        console.log(files[0]);
        const responsedata = await ConstructorEventPost(SaveWatermarkImage, {
            imgtype: files[0].type,
            imgname: files[0].name
        });

        // Adjusted the way watermark image name is created
        const fileExtension = files[0].type.split("/")[1];
        const waterMarkImage = `${files[0].name}.${fileExtension}`;

        localStorage.setItem("watermarkimagekey", waterMarkImage);

        // console.log(the water saved in local is ${waterMarkImage});

        uploadImage(responsedata, files[0])
            .then((response) => {
                console.log("Image uploaded successfully:", response);
            })
            .catch((error) => {
                console.error("Error uploading image:", error);
            });
        console.log(responsedata, "link");

    };

    const uploadImage = async (link, file) => {
        console.log(file.type);
        const responsedata1 = await axios
            .put(link, file, {
                headers: {
                    "Content-Type": file.type,
                },
            })
            .then(() => {
                console.log("Profile Image Updated");
                navigate(uri.current, { state: { order_of_execution: OrderOfExecution + 1 } });
            });
        console.log(responsedata1, "response");
        setLoading(false);
    };

    const handlewatermarkselect = (index) => {
        setWatermarkselect(index);
        setWatermarkselect(null);
    };


    const onClickNext = () => {
        // setInspectionReportSignatureImage(WatermarkImage);
        setInspectionReportwatermarkImage(watermark);
        setInspectionReportTableData(TableData);
        navigate(uri.current, { state: { order_of_execution: OrderOfExecution + 1 } });
    };

    const addNewWatermark = () => {
        const fileInput = document.getElementById('ferrari');
        if (fileInput) {
            fileInput.click();
        }
    };


    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="page-title">
                        <h4>Add Report Data</h4>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            {TableData.map((item, index) => (
                                <div className="col-lg-6 col-sm-6 col-12">
                                    <div className="form-group" key={index}>
                                        <div style={{ display: "inline-flex", alignItems: "center" }}>
                                            <label>{item.name} </label>
                                            <iconify-icon icon="mdi:close-outline" style={{ color: 'red', fontSize: "14px", paddingTop: "0px", marginBottom: "8px", paddingLeft: "8px" }} onClick={() => DeleteRawTableDataRAw(item.name)}></iconify-icon>
                                        </div>

                                        <input type="text" value={item.data} onChange={(e) => HandleDataChange(e.target.value, item.name)} />

                                    </div>
                                </div>
                            ))
                            }
                            <div className="col-lg-12 mt-4">
                                {!AddRow ?
                                    <div className="AddNewTableIcon" onClick={() => setAddRow(true)} ><iconify-icon icon="mingcute:add-fill" style={{ color: '#FF9F43', fontSize: "18px" }}></iconify-icon></div>
                                    : <div className="col-lg-4 col-sm-4 col-12">
                                        <div className="AddnewTableKey" >

                                            <input type="text" placeholder='Enter the Table Key' onChange={(e) => setNewRawName(e.target.value)} />
                                            <iconify-icon icon="mdi:tick-outline" style={{ color: 'green', fontSize: "20px", paddingRight: "10px" }} onClick={CreateNewTableDataRaw}></iconify-icon>
                                            <iconify-icon icon="iconamoon:close-bold" style={{ color: 'red', fontSize: "20px" }} onClick={() => { setAddRow(false); setNewRawName("") }}></iconify-icon>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="pb-4">
                                        <div className="image-container-inspection-content-addNew" onClick={addNewWatermark}>
                                            <iconify-Icon icon="akar-icons:plus" style={{ paddingTop: "2px" }} /> Add Watermark
                                        </div>
                                        {/* {watermarkselect !== null && (
                                            <div className="image-container-inspection-content-removeButton" onClick={removewatermark}>
                                                <iconify-Icon icon="eva:close-fill" style={{ paddingTop: "2px" }} /> Remove Signature
                                            </div>
                                        )} */}
                                    </div>
                                    <div className="row">
                                        {watermark.map((image, index) => (
                                            <div className="col-lg-3 col-sm-6 col-12 pb-4" key={index} onClick={() => handlewatermarkselect(index)}>
                                                <div className={watermarkselect === index ? 'image-container-inspection-image-remove' : 'image-container-inspection'}>
                                                    <img src={URL.createObjectURL(image)} alt={`Signature ${index}`} style={{ borderRadius: "10px" }} />
                                                </div>
                                            </div>
                                        ))}
                                        <input
                                            type="file"
                                            id="ferrari"

                                            accept="image/*"
                                            onChange={handlewatermarkUpload} // Connect onChange event to handlewatermarkchange function
                                            style={{ display: 'none' }}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-4">
                                <button className="btn btn-cancel me-2">
                                    Cancel
                                </button>
                                <button className="btn btn-submit" onClick={onClickNext} >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default InspectionReportTable;