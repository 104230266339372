import React from 'react'
import { Route, Routes } from 'react-router-dom';
import AddCatogory from './AddCatogory';
import AddNewItem from './AddNewItem';
import ItemList from './ItemList';
import CatogoryList from './CatogoryList';
import EditItem from './EditItem';
import EditCategory from './EditCategory';  
import StockManagement from './StockManagement';

export const Item_Index = () =>(
    <Routes>
        <Route path="/ListCatogory" element={<CatogoryList/>} />
        <Route path="/ListItem" element={<ItemList/>} />
        <Route path="/AddNewCatogory" element={<AddCatogory/>} />
        <Route path="/AddNewItem" element={<AddNewItem/>} />
        <Route path="/EditItem" element={<EditItem/>} />
        <Route path="/EditCatogory" element={<EditCategory/>} />
        <Route path="stockManagement" element={<StockManagement/>}/>
    </Routes>
)





